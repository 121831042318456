import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Image } from "../../style/Image";
import { Text } from "../../style/Text";
import { Heading } from "../../style/Heading";
import { Wysiwyg } from "../../style/Wysiwyg";
import { IcoBricks, IcoCalc, IcoCar, IcoHouse } from "../../assets/Svg";
import { StaticImage } from "gatsby-plugin-image";

const divisions = [
  {
    name: "Finanční poradenství",
    description: "Poradenství v oblastech, jako je životní a majetkové pojištění nebo úvěry a investice, jsou hlavním stavebním pilířem této divize.",
    icon: IcoCalc,
  },
  {
    name: "Reality",
    description:
      "Makléřům umíme zajistit práci. Díky statisícům klientů v naší databázi jsme schopni získat nemovitosti do nabídky a zajistit právní podporu. ",
    icon: IcoHouse,
  },
  {
    name: "Cenné kovy",
    description:
      "Investiční slitek jako speciální dárek k narozeninám nebo výročí? O spoření do zlata a stříbra je u klientů stále vyšší zájem, tak toho využijte.",
    icon: IcoBricks,
  },
  {
    name: "Autopojištění",
    description: "V rámci podpory máme špičkový srovnavač autopojištění a kvalitní klientský servis, kde pomáháme s nahlášením a řešením pojistných událostí.",
    icon: IcoCar,
  },
];

const Divisions = () => (
  <Wrapper>
    <Container flexDirection="column" mt={[10, null, 3]} mb={[4, null, null, 0]}>
      <Heading as="h2" mb={[3, null, 4]} lineHeight={0} uppercase fontWeight="light" fontSize={[4, null, 5]}>
        Široká škála divizí pro spolupráci
      </Heading>

      <Flex flexDirection={"row"} flexWrap={"wrap"} mb={4}>
        <Box flex={"1 0 33%"} maxWidth={[null, null, null, 387]} mr={[null, null, 4, 4]} mb={[4, null, null, 0]}>
          <StaticImage src={"../../assets/images/kariera-divisions.jpg"} style={{ borderRadius: "6px", width: "100%" }} />
        </Box>
        <Box flex={["0 1 100%", null, "0 1 50%", null, "0 1 50%"]} maxWidth={[null, null, null, "50%"]}>
          <Heading as={"h4"} fontSize={20} lineHeight={1} fontWeight={"normal"} color={"headlines"} mb={[3, null, null, 2]}>
            V KAPITOLu máme několik divizí, rozdělených dle služeb pro klienty. Vy sami si můžete určit a vybrat startovní čáru, ze které chcete začít kariéru v
            KAPITOLu. A můžete si navíc i vybrat směr, kterým se budete chtít rozvíjet.
          </Heading>
          <Wysiwyg
            fontSize={16}
            lineHeight={"24px"}
            dangerouslySetInnerHTML={{
              __html:
                "V obchodních týmech či na centrále máme pro každou divizi vždy několik odborníků a specialistů, kteří vám ochotně nabídnou konkrétní odpověď, radu nebo vám pomohou daný obchod úspěšně dotáhnout do konce.",
            }}
          />
        </Box>
      </Flex>

      <Flex flexWrap="wrap" justifyContent="space-between">
        {divisions.map((division, index) => (
          <Flex
            p={3}
            pb={4}
            key={index}
            width={[1, "calc(50% - 10px)", null, "calc(25% - 20px)"]}
            flexDirection={["flex", "column"]}
            borderRadius={1}
            mb={[3, null, 4, 5]}
            bg="panelSecondary"
          >
            <Box width={1} maxWidth={["63px", null, "97px"]} mr={[10, 0]}>
              <Image src={division.icon} width={1} display="block" />
            </Box>

            <Box>
              <Heading as="h3" fontSize={[2, null, 3]} fontWeight="semiBold" mb={1} mt={[2, 0]} color="link">
                {division.name}
              </Heading>

              <Text lineHeight={1} fontSize={[15, 16]} dangerouslySetInnerHTML={{ __html: division.description }} />
            </Box>
          </Flex>
        ))}
      </Flex>
    </Container>
  </Wrapper>
);

export default Divisions;
