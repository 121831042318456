import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";

import "swiper/swiper-bundle.min.css";

const tools = [
  {
    heading: "Nejmodernější poradenská aplikace",
    description: "Kappka - Od úvodní schůzky s klientem přes sběr dat až po přípravu finančího plánu.",
    image: require("../../assets/images/career/tools/kappka.png").default,
  },
  {
    heading: "Srovnávače",
    description: "Dodáme vám on-line kalkulačky našich partnerů a srovnávače produktů jednotlivých divizí.",
    image: require("../../assets/images/career/tools/srovnavace.png").default,
  },
  {
    heading: "Automatické upozorňování na obchodní příležitosti",
    description:
      "Máme pro vás komplexní přehled pro práci poradce i vašeho týmu na jednom místě, který navíc nabízí automatické upozornění na obchodní příležitosti.",
    image: require("../../assets/images/career/tools/portal.png").default,
  },
  {
    heading: "Znalostní báze",
    description:
      "V Centru nápovědy naleznete návody k programům a aplikacím, pracovní video-manuály a odpovědi na vaše dotazy. Navíc jsou vám k dispozici naši specialisti na centrále.",
    image: require("../../assets/images/career/tools/zendesk.png").default,
  },
];

SwiperCore.use([Autoplay]);

const ToolsCarousel = () => (
  <>
    <Wrapper backgroundColor={"panelPrimary"} minHeight={[null, null, null, 640]} className={"wrapper-tools-carousel"} mb={5}>
      <Container>
        <Flex flexDirection={"column"} maxWidth={486} pt={5}>
          <Heading as={"h3"} fontSize={[24, null, 28]} lineHeight={["26px", null, "29px"]} fontWeight={300} uppercase>
            Vybavíme Vás špičkovými nástroji pro efektivní práci
          </Heading>
        </Flex>
      </Container>
      <Swiper loop={true} slidesPerView={1} spaceBetween={20} centeredSlides={true} autoplay={{ delay: 6000 }} effect={"slide"}>
        {tools.map((tool, index) => (
          <SwiperSlide
            tag={Flex}
            flexDirection={"column"}
            alignItems={[null, null, null, "center"]}
            position={"relative"}
            key={index}
            backgroundImage={[null, null, null, null, `url(${tool.image})`]}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={["bottom", null, null, null, "bottom right"]}
            backgroundSize={["100%", null, "auto"]}
            height={"auto"}
          >
            <Container
              height={[null, null, null, null, "520px"]}
              flexDirection={"column"}
              width={["calc(100% - 47px)", null, null, "calc(100% - 60px)", "1200px"]}
              mb={[4, null, null, null, 0]}
            >
              <Heading
                as={"h2"}
                fontSize={[5, null, 48]}
                lineHeight={["32px", null, "56px"]}
                mb={24}
                maxWidth={[null, null, null, null, 486]}
                mt={[5, null, null, null, 132]}
                dangerouslySetInnerHTML={{ __html: tool.heading }}
              />
              <Text
                fontSize={[15, null, 20]}
                lineHeight={["20px", null, "23px"]}
                maxWidth={[null, null, null, null, 486]}
                dangerouslySetInnerHTML={{ __html: tool.description }}
              />
            </Container>
            <Image
              display={["block", null, null, null, "none"]}
              src={tool.image}
              width={["calc(100% + 20px)", null, "calc(60% + 20px)"]}
              ml={"auto"}
              height={"auto"}
              mt={"auto"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  </>
);

export default ToolsCarousel;
