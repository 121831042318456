import styled from "styled-components";
import { Box } from "../Grid";
import themeGet from "@styled-system/theme-get";

const CareerVideo = styled(Box)`
  width: 100%;
  iframe {
      position: relative;
      top: 75px;
      width: 100%;
      aspect-ratio: 16/9;
  }
	@media screen and (max-width: ${themeGet("breakpoints.lg")}) {
      padding-bottom: 2em;
      iframe {
	      position: static;
      }
  }
`

CareerVideo.displayName = "CareerVideo";

export { CareerVideo }