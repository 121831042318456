import React, {Fragment, isValidElement} from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";

import Link from "../../components/Link";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import Divisions from "../../components/Divisions";
import ContactForm from "../../components/ContactForm";
import MobileGallery from "../../components/MobileGallery";
import ToolsCarousel from "../../components/ToolsCarousel";
import StoryBlockSlider from "../../components/StoryBlock/StoryBlockSlider";

import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { List } from "../../style/List";
import Icon from "../../style/Icon";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { Button } from "../../style/Button";
import { randomizeArray } from "../../utils";
import Breadcrumb from "../../components/Breadcrumb";
import {Wysiwyg} from "../../style/Wysiwyg";
import {CareerVideo} from "../../style/Carrer";

const Lightbox = loadable(() => import("../../components/Lightbox"));

const benefits = [
  {
    image: require("../../assets/images/career/benefits/komplexni-nabidka.jpg").default,
    title: "Komplexní nabídka",
    description: [
      "Máme online zasmluvnění spolupracovníků, nabízíme kompletní zaškolení, zajistíme registrace na ČNB, pomůžeme s převodem kmenů",
      "Dodáme vám on-line kalkulačky našich obchodních partnerů a&nbsp;naučíme vás využívat srovnávače a&nbsp;sjednávače produktů v&nbsp;jednotlivých divizích",
      "Zajistíme přístup k online školení na odborné zkoušky ČNB",
    ],
  },
  {
    image: require("../../assets/images/career/benefits/team.jpg").default,
    title: "Fungujeme jako tým",
    description: [
      "Nabízíme moderní ekosystém pro každodenní práci vč. podpory marketingu a centrály",
      "Tým produktových specialistů funguje jako 'přítel na telefonu', když potřebujete poradit",
      "Aktivní call-centrum vám zajistí naplnění vaše pracovního kalendáře dle vašich požadavků a potřeb",
    ],
  },
  {
    image: require("../../assets/images/career/benefits/vztah-s-klientem.jpg").default,
    title: "Vztah s klientem",
    description: [
      "Naše call-centrum vám může pomoci s běžnou servisní prací u vašeho klientského kmene (např. navolání a domlouvání schůzek, představení nového produktu atd.)",
      "Klientské centrum umí pomoci a poradí vašim klientům s hlášením pojistných událostí",
      "Umíme za vás pohlídat neplacení a storna klientských smluv a mnoho dalšího",
    ],
  },
  {
    image: require("../../assets/images/career/benefits/legrace.jpg").default,
    title: "Po práci legraci",
    description: [
      "Za dobře odvedenou práci nabízíme řadu nadstandardních odměn",
      "Tmelíme kolektiv napříč celým KAPITOLem na firemní konferenci a teambuildingových aktivitách",
      "Můžete se těšit na zajímavé motivační soutěže, kde ti nejlepší zažívají nevšední zážitky",
    ],
  },
];

const gallery = [
  {
    thumb: require("../../assets/images/gallery/thumb/1.jpg").default,
    image: require("../../assets/images/gallery/1.jpg").default,
  },
  {
    thumb: require("../../assets/images/gallery/thumb/2.jpg").default,
    image: require("../../assets/images/gallery/2.jpg").default,
  },
  {
    thumb: require("../../assets/images/gallery/thumb/3.jpg").default,
    image: require("../../assets/images/gallery/3.jpg").default,
  },
  {
    thumb: require("../../assets/images/gallery/thumb/4.jpg").default,
    image: require("../../assets/images/gallery/4.jpg").default,
  },
  {
    thumb: require("../../assets/images/gallery/thumb/5.jpg").default,
    image: require("../../assets/images/gallery/5.jpg").default,
  },
  {
    thumb: require("../../assets/images/gallery/thumb/6.jpg").default,
    image: require("../../assets/images/gallery/6.jpg").default,
  },
];

const Career = ({
  uri,
  data: {
    wp: {
      page: {
        title,
        pageContent: { banner, selectedStoriesCareer: stories },
        seo: { metaDesc },
      },
    },
  },
}) => {
  const breadcrumbs = [
    {
      label: title,
      link: uri,
    },
  ];
  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <Wrapper
        bg={"panelPrimary"}
        mb={[4, null, banner?.date ? 32 : 5]}
      >
        <Container>
          <Flex
            width={"100%"}
            minHeight={["none", null, null, "380px"]}
            flexDirection={["column", null, null, "row"]}
            justifyContent={"space-between"}
          >
            <Flex
              flexDirection={"column"}
              alignItems={"flex-start"}
              pt={[32, null, 20]}
              pb={[4, null, 4]}
              pr={[0, null, null, 32]}
              flex={["none", null, null, "0 0 50%"]}
            >
              {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}
              {banner?.title && (
                <Heading
                  as={"h1"}
                  variant={"h1"}
                  dangerouslySetInnerHTML={{ __html: banner?.title }}
                  mb={3}
                  uppercase
                />
              )}
              {banner?.content && (
                <Fragment>
                  {isValidElement(banner.content) ? (
                    <Fragment>{banner.content}</Fragment>
                  ) : (
                    <Wysiwyg
                      dangerouslySetInnerHTML={{ __html: banner.content }}
                      lineHeight={3}
                      mb={26}
                    />
                  )}
                </Fragment>
              )}
              {banner?.cta && (
                <Fragment>
                  {isValidElement(banner.cta) ? (
                    <Fragment>{banner.cta}</Fragment>
                  ) : (
                    <Button
                      as={Link}
                      href={banner.cta.url}
                      target={banner.cta.target}
                      display="inline-block"
                    >
                      {banner.cta.title}
                    </Button>
                  )}
                </Fragment>
              )}
            </Flex>
            
            <CareerVideo>
              <iframe src="https://www.youtube.com/embed/iotvVDB8OSQ?autoplay=0&rel=0" 
                      frameborder="0" 
                      title="YouTube video player" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      allowfullscreen 
              />
            </CareerVideo>

          </Flex>
        </Container>
      </Wrapper>
      
      

      <Divisions />

      <ToolsCarousel />

      {stories && (
        <>
          <Wrapper>
            <Container flexDirection="row" justifyContent={"space-between"}>
              <Flex width={[1, null, null, "70%"]} maxWidth={["600px", null, null, "70%"]} pb={[0, null, null, 0]}>
                <Heading as="h2" mb={[2, null, 4]} fontSize={[4, null, 5]} lineHeight={0} color="headlines" fontWeight="light" letterSpacing="caps" uppercase>
                  Příběhy našich poradců a manažerů
                </Heading>
              </Flex>
            </Container>
          </Wrapper>
          <StoryBlockSlider stories={randomizeArray(stories)} mb={4} showLeftPane showProducts hideLink />
        </>
      )}

      <Wrapper pb={[3, null, 96]} pt={["mobileSpacer", null, 3]}>
        <Container flexDirection="column">
          <Box maxWidth={895} mb={[4, null, null, null, 71]}>
            <Heading as="h2" fontSize={[4, null, 28]} lineHeight={0} fontWeight="light" color={"headlines"} uppercase mb={"0.5em"}>
              BENEFITY SPOLUPRÁCE S&nbsp;KAPITOLEM
            </Heading>
            <Text fontSize={[18, null, 3]} lineHeight={1}>
              Pro budování kariéry ve světě finančního poradenství vám nabízíme stabilitu, podpořenou bohatými zkušenostmi na českém trhu a zázemím nadnárodní
              finanční skupiny Vienna Insurance Group (VIG). Máme pro vás špičkové digitální nástroje 21. století a podporu celé centrály, kde jsou odborníci a
              experti na jednotlivé produkty a klientské služby.
            </Text>
          </Box>

          <Flex flexWrap="wrap" justifyContent="space-between">
            {benefits.map((tool, index) => (
              <Flex flexDirection="column" key={index} width={[1, "calc(50% - 10px)", null, "calc(25% - 12.5px)"]} mb={[4, null, 0]}>
                <Image src={tool.image} display="block" mb={3} width={1} flexOrder={2} borderRadius={1} />
                <Box pl={[0, null, null, 20]}>
                  <Heading as="h3" lineHeigh={1} fontSize={[18, 3]} fontWeight="regular" color={"headlines"} mb={3} order={[-1, 0]}>
                    {tool.title}
                  </Heading>
                  <List pl={[20, null, null, 0]}>
                    {tool.description.map((item, index) => (
                      <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                  </List>
                </Box>
              </Flex>
            ))}
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="headlines" pt={[4, null, 5]} pb={[4, null, 90]}>
        <Container flexDirection="column">
          <Heading as="h2" lineHeight={1} fontSize={5} mb={3} color="cta">
            JAK TO U NÁS VYPADÁ
          </Heading>

          <Lightbox gallery={gallery} />

          <Box display={["block", null, "none"]}>{typeof window !== "undefined" && <MobileGallery images={gallery} />}</Box>
        </Container>
      </Wrapper>

      <Wrapper pt={[4, null, 5, 115]} pb={[44, null, 104]} id={"chci-se-stat-poradcem"}>
        <Container flexDirection="column">
          <Heading as="h3" fontSize={[4, null, 5]} fontWeight="light" mt={2} mb={45} textAlign={["left", null, "center"]} lineHeight={0} uppercase>
            Máte zájem o&nbsp;spolupráci s&nbsp;KAPITOLem?
          </Heading>
          <Flex width={1} flexDirection={["column", null, "row"]}>
            <Box width={[1, null, 1 / 2]} maxWidth={["auto", null, 500]} mr="auto" pr={[0, null, 4]} mb={[4, null, 0]}>
              <Box boxShadow="one" borderRadius={1} bg="panelPrimary" px={22} py={4}>
                <Heading maxWidth={340} as="h3" uppercase fontSize={3} fontWeight="regular" lineHeight={1} mb={4}>
                  Pro Váš úspěšný start nabízíme:
                </Heading>

                <List variant="check" lineHeight={1}>
                  <Text as="li" fontSize={2} mb={2}>
                    <Icon icon="icon-check" color="cta" size={17} />
                    <Text as="strong" fontWeight="semiBold" color="headlines">
                      Právní asistence při přestupu
                    </Text>
                    <br />
                    Poradíme Vám, jak postupovat v&nbsp;souladu s&nbsp;Vaší stávající smlouvou a&nbsp;legislativou
                  </Text>
                  <Text as="li" fontSize={2} mb={3}>
                    <Icon icon="icon-check" color="cta" size={17} />
                    <Text as="strong" fontWeight="semiBold" color="headlines">
                      Organizační a&nbsp;administrativní asistence
                    </Text>
                    <br />
                    Pomůžeme Vám vyřídit všechny možné formality a&nbsp;administrativu
                  </Text>
                  <Text as="li" fontSize={2}>
                    <Icon icon="icon-check" color="cta" size={17} />
                    <Text as="strong" fontWeight="semiBold" color="headlines">
                      Finanční podpora
                    </Text>
                    <br />
                    Nabídneme finanční podporu na pokrytí nákladů souvisejících s&nbsp;nástupem do&nbsp;KAPITOLu
                  </Text>
                </List>
              </Box>
            </Box>

            <Box width={[1, null, 1 / 2]}>
              <ContactForm type="career" />
            </Box>
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Career;

export const pageQuery = graphql`
  query CAREER_PAGE_QUERY {
    wp {
      page(id: "kariera", idType: URI) {
        title
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        pageContent {
          banner {
            fieldGroupName
            title
            titletop
            cta {
              target
              title
              url
            }
            content
          }
          template
          selectedStoriesCareer {
            ...StoryInformation
          }
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
