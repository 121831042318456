import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Lazy, Navigation, Thumbs } from "swiper";

import { Box } from "../../style/Grid";
import { SwiperGallery } from "../../style/SwiperGallery";

SwiperCore.use([Controller, Lazy, Navigation, Thumbs]);

const MobileGallery = gallery => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div>
      <Swiper
        tag={SwiperGallery}
        loop={true}
        slidesPerView={1}
        spaceBetween={10}
        loopedSlides={5}
        navigation
        slideToClickedSlide={true}
        thumbs={{ swiper: thumbsSwiper }}
        controller={{ control: thumbsSwiper }}
      >
        {gallery.images.map((item, index) => (
          <SwiperSlide
            tag={Box}
            height={"220px"}
            key={index}
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          />
        ))}
      </Swiper>

      <Swiper
        tag={Box}
        id={"thumbs"}
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={5}
        slidesPerView={4}
        centeredSlides={true}
        loopedSlides={5}
        touchRatio={0.2}
        slideToClickedSlide={true}
        mt={10}
      >
        {gallery.images.map((item, index) => (
          <SwiperSlide
            key={index}
            tag={Box}
            height={"60px"}
            style={{
              backgroundImage: `url(${item.thumb})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          />
        ))}
      </Swiper>
    </div>
  );
};

export default MobileGallery;
