import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const SwiperGallery = styled(Box)`
  .swiper-button-prev,
  .swiper-button-next {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 0 0 2px ${themeGet("colors.white.100")};
    &:after {
      font-weight: bold;
      font-size: 15px;
      color: ${themeGet("colors.white.100")};
    }
    &:focus {
      outline: none;
    }
  }
`;

export { SwiperGallery };
